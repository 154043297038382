import React from "react"
//import { Link } from "gatsby"

import Layout from "../components/bs/layout"
//import Image from "../components/image"
import SEO from "../components/bs/seo"

import MainImg from "../images/main_bg.jpg"

import shape1 from "../images/dec/1.png"
import shape2 from "../images/dec/2.png"
import shape3 from "../images/dec/3.png"
import shape4 from "../images/dec/4.png"
import shape5 from "../images/dec/5.png"
import shape6 from "../images/dec/6.png"
import shape7 from "../images/dec/7.png"
import shape8 from "../images/dec/8.png"

const ZastitaPodataka = () => (
  <Layout>
    <SEO
      title="IMMMUNO NATURA – IZJAVA O ZAŠTITI LIČNIH PODATAKA"
      description="GREENWELL’S DOO, vlasnik web stranice www.immuno-natura.ba obrađuje podatke korisnika ove web stranice, a koje su namijenjene informisanju korisnika o novostima u našoj kompaniji."
    />

    <section
      id="_heroPage"
      className="hero is-large"
      style={{ backgroundImage: `url(${MainImg})` }}
    >
      <div id="bgHero">
        <span className="shape1">
          <img src={shape1} alt="Shape 1" />
        </span>
        <span className="shape2">
          <img src={shape2} alt="Shape 2" />
        </span>
        <span className="shape3">
          <img src={shape3} alt="Shape 3" />
        </span>
        <span className="shape4">
          <img src={shape4} alt="Shape 4" />
        </span>
        <span className="shape5">
          <img src={shape5} alt="Shape 5" />
        </span>
        <span className="shape6">
          <img src={shape6} alt="Shape 6" />
        </span>
        <span className="shape7">
          <img src={shape7} alt="Shape 7" />
        </span>
        <span className="shape8">
          <img src={shape2} alt="Shape 8" />
        </span>
      </div>
      <div className="hero-body">
        <div className="container">
          <div className="title">ZAŠTITA PODATAKA</div>
        </div>
      </div>
    </section>

    <span className="_line" />

    <section id="_pageContent_other" className="section">
      <span className="_dec2">
        <img src={shape8} alt="Dec 2" />
      </span>
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <div className="columns">
              <div className="column _headline">
                <div className="content">
                  <p>
                    GREENWELL&rsquo;S DOO, vlasnik web stranice{" "}
                    <a href="http://www.immuno-natura.ba">
                      www.immuno-natura.ba
                    </a>{" "}
                    obrađuje podatke korisnika ove web stranice, a koje su
                    namijenjene informisanju korisnika o novostima u
                    na&scaron;oj kompaniji. Podaci se obrađuju u skladu sa
                    važećim propisima kojima se regulira za&scaron;tita ličnih
                    podataka. Na web stranici{" "}
                    <a href="http://www.immuno-natura.ba">
                      www.immuno-natura.ba
                    </a>{" "}
                    prikupljamo informacije koje identifikuju posjetioca
                    (korisnika/kupca) ove online trgovine pri vr&scaron;enju
                    određenih aktivnosti kao &scaron;to su ispunjavanje anketa,
                    slanje komentara i sl. Prilikom ispunjavanja kontaktnog
                    formulara odnosno formulara za NEWSLETTER posjetilac ima
                    mogućnost uno&scaron;enja svoji ličnih podataka (ime,
                    prezime, adresa, kontakt telefon, e-mail adresa i dr).
                    Uno&scaron;enje ličnih podataka je odluka posjetioca koji
                    ukoliko ne unese tražene podatke za navedene servise neće
                    biti u mogućnosti da ih koristi.
                  </p>
                  <p>
                    Online trgovina www.zdravo.ba u svojim bazama podataka ne
                    prikuplja i ne čuva informacije vezane za platne kartice
                    posjetilaca i korisnika usluga koje se pružaju putem
                    navedenih radnji.&nbsp;
                  </p>
                  <h4>
                    <strong>
                      Op&scaron;te informacije o vlasniku web stranice{" "}
                      <a href="http://www.immuno-natura.ba">
                        www.immuno-natura.ba
                      </a>{" "}
                    </strong>
                  </h4>
                  <p>
                    Vlasnik web stranice{" "}
                    <a href="http://www.immuno-natura.ba">
                      www.immuno-natura.ba
                    </a>
                    &nbsp;je GREENWELL&lsquo;S DOO sa sjedi&scaron;tem u
                    Sarajevu u ulici M. Tita 11, Bosna i Hercegovina.
                  </p>
                  <p>ID broj firme je:&nbsp;4202535680007&nbsp;</p>
                  <p>
                    Email:&nbsp;
                    <a href="mailto:info@immuno-natura.ba">
                      <strong>info@immuno-natura.ba</strong>
                    </a>
                  </p>
                  <p>
                    Telefon:&nbsp;<strong>+38761787346</strong>
                  </p>
                  <p>
                    GREENWELL&lsquo;S DOO poduzima mjere kako bi obrada osobnih
                    zadataka ispitanika bila zakonita, po&scaron;tena i
                    transparentna.
                  </p>
                  <p>
                    Izjava o privatnosti i za&scaron;titi podataka odnosi se na
                    povjerljivost podataka koji se deponuju, prikupljaju i
                    smje&scaron;taju u baze prilikom kori&scaron;tenja web
                    stranice{" "}
                    <a href="http://www.immuno-natura.ba">
                      www.immuno-natura.ba
                    </a>
                    &nbsp;sa ciljem uspostavljanja kontakta odnosno prijave na
                    NEWSLETTER.
                  </p>
                  <p>
                    Svi prikupljeni podaci biće upotrebljeni samo sa ciljem
                    realizacije eventualne promotivne aktivnosti,
                    pobolj&scaron;anja na&scaron;ih usluga, informisanja
                    korisnika o novostima.
                  </p>
                  <p>Ovom Izjavom Vas želimo obavijestiti o:</p>
                  <ul>
                    <li>1. načinu prikupljanja Va&scaron;ih ličnih podataka</li>
                    <li>2. vrsti podataka koje prikupljamo</li>
                    <li>3. načinu upotrebe prikupljenih podataka</li>
                    <li>4. periodu u kojem se čuvaju prikupljeni podaci</li>
                    <li>5. za&scaron;titi prikupljenih podataka</li>
                    <li>6. pristupu drugih stranaka prikupljenim podacima</li>
                    <li>
                      7. vrsti kolačića koji se koriste na online trgovini
                    </li>
                  </ul>
                  <p>
                    Zbog specifilčnosti digitalnog poslovanja pozivamo sve
                    posjetioce web stranice{" "}
                    <a href="http://www.immuno-natura.ba">
                      www.immuno-natura.ba
                    </a>{" "}
                    da pažljivo pročitaju izjavu o povjerljivosti kako bi na
                    ispravan način razumjeli koje informacije treba da deponuju
                    i u koju svrhu će deponovani podaci biti iskori&scaron;ćeni.
                  </p>
                  <p>
                    Pristupanjem ili upotrebom na&scaron;e web stranice
                    posjetilac potvrđuje da je pročitao, razumio i da se slaže
                    sa svim uslovima povjerljivosti podataka i načinom
                    kori&scaron;tenja web stranice{" "}
                    <a href="http://www.immuno-natura.ba">
                      www.immuno-natura.ba
                    </a>{" "}
                    .
                  </p>
                  <p>
                    Ukoliko se korisnik ne slaže sa ovom izjavom o
                    povjerljivosti podataka upućujemo ga da na web stranici{" "}
                    <a href="http://www.immuno-natura.ba">
                      www.immuno-natura.ba
                    </a>
                    &nbsp;ne ostavlja i ne deponuje nikave lične podatke i da je
                    samo koristi u eventualne informativne svrhe.
                  </p>
                  <h4>1. Način prikupljanja Va&scaron;ih ličnih podataka</h4>
                  <p>
                    Web stranica{" "}
                    <a href="http://www.immuno-natura.ba">
                      www.immuno-natura.ba
                    </a>
                    &nbsp;prikuplja Va&scaron;e lične podatke pri ispunjavanju
                    kontaktnih formulara odnosno formulara za Newsletter
                    &scaron;to je ujedno i pravna osnova obrade Va&scaron;ih
                    ličnih podataka. Prilikom ispunjavanja spomenutih formulara
                    potrebno je ispuniti sva obavezna polja koja nam služe za
                    uspostavljanje kontakta sa Vama ili za mogućnost direktnog
                    informisanja o novostima u na&scaron;oj kompaniji.
                  </p>
                  <p>
                    Internetsku adresu posjetitelja (IP adresa) prikupljamo
                    prilikom kori&scaron;tenja na&scaron;e internetske stranice.
                  </p>
                  <h4>2. Koji lični podaci bivaju prikupljani?</h4>
                  <p>
                    Podaci koje prikupljamo kako bismo mogli uspostaviti kontakt
                    s Vama odnosno informisati Vas o novostima u na&scaron;oj
                    kompaniji su: ime, prezime, telefon i email adresa.
                  </p>
                  <p>
                    Internetska (IP adresa) koristi se isključivo za izradu
                    statističkih izvje&scaron;taja koja se obavlja putem alata
                    Google Analytics.
                  </p>
                  <h4>
                    3. Kako i za &scaron;to upotrebljavamo va&scaron;e podatke
                  </h4>
                  <p>
                    Va&scaron;e podatke koje ste nam dali na ranije opisani
                    način upotrebljavamo za odgovore na Va&scaron;e upite i
                    informisanje o novostima u kompaniji.
                  </p>
                  <p>
                    Va&scaron;e lične podatke također možemo koristiti u svrhu
                    izvr&scaron;enja zakonskih ili pravnih obveza kojima
                    podliježemo.
                  </p>
                  <h4>4. Koliko dugo čuvamo va&scaron;e lične podatke?</h4>
                  <p>
                    Period u kojem pohranjujemo i čuvamo Va&scaron;e lične
                    podatke ovisi o tome o kojoj se vrsti podataka rada, o svrsi
                    za koju su isti prikupljeni te o zakonskim i pravnim
                    obavezama kojima web stranica{" "}
                    <a href="http://www.immuno-natura.ba">
                      www.immuno-natura.ba
                    </a>
                    &nbsp;podliježe.
                  </p>
                  <p>
                    Va&scaron;e osobne podatke možemo i prema Va&scaron;oj želji
                    i na Va&scaron; zahtjev u svako vrijeme izbrisati.
                  </p>
                  <h4>
                    5. Način na koji &scaron;titimo Va&scaron;e lične podatke
                  </h4>
                  <p>
                    Web stranica{" "}
                    <a href="http://www.immuno-natura.ba">
                      www.immuno-natura.ba
                    </a>
                    &nbsp;poduzela je potrebne tehničke mjere kako bi
                    za&scaron;titila Va&scaron;e osobne podatke od
                    neovla&scaron;tenog pristupa, upotrebe ili odavanja. Za
                    pohranu Va&scaron;ih osobnih podataka koristimo savremeni
                    informacijski sustav koji podliježe stalnim tehničkim
                    unaprjeđenjima u skladu sa tehnolo&scaron;kim razvojem.
                  </p>
                  <p>
                    Također su uspostavljene i organizacijske mjere kako bi
                    pristup podacima imale samo ovla&scaron;tene osobe koje
                    sudjeluju u cjelokupnom procesu uspostavljanja kontakta
                    odnosno prikupljanju adresa i brojeva telefona za
                    Newsletter. Ove osobe imaju obvezu čuvanja povjerljivosti
                    osobnih podataka kupaca/korisnika usluga.
                  </p>
                  <h4>6. Da li i s kime dijelimo va&scaron;e lične podatke?</h4>
                  <p>
                    Web stranica{" "}
                    <a href="http://www.immuno-natura.ba">
                      www.immuno-natura.ba
                    </a>
                    , lične podatke svojih kupaca/korisnika usluga dijeli sa
                    drugima u samo ukoliko postoji zakonska/pravna obaveza da se
                    dostave određeni podaci nadležnom tijelu/instituciji.
                  </p>
                  <h4>7. Koje kolačiće koristimo</h4>
                  <p>
                    Kolačić (eng. Cookie) je informacija pohranjena na kompjuter
                    ili mobilni uređaj posjetitelja web stranice.
                  </p>
                  <p>
                    Web stranica{" "}
                    <a href="http://www.immuno-natura.ba">
                      www.immuno-natura.ba
                    </a>
                    &nbsp;koristi kolačiće isključivo kako bi Vama kao
                    posjetitelju web stranice omogućili kvalitetnije korisničko
                    iskustvo.
                  </p>
                  <p>
                    Postoji vi&scaron;e vrsta kolačića, a razlikuju se ovisno o
                    tome koliko traju ili čiji su.
                  </p>
                  <p>
                    Ovisno o trajanju razlikujemo: trajne (persistent cookies) i
                    privremene (session cookies) kolačiće. Ovisno o tome čiji
                    su, razlikujemo: kolačiće prve strane (first party cookies)
                    i kolačiće treće strane (third party cookies).
                  </p>
                  <p>
                    Trajni kolačići su oni koji na računalu ostaju nakon
                    zatvaranja web browser-a, a datum isteka im je daleko u
                    budućnosti te na web browser-u ostaju dok taj rok ne istekne
                    ili dok ih Vi ne obri&scaron;ite. Web stranica{" "}
                    <a href="http://www.immuno-natura.ba">
                      www.immuno-natura.ba
                    </a>
                    &nbsp;koristi ovu vrstu kolačića da bi stranica bila brža i
                    time korisnici maksimalno mogli uživati u pregledanju
                    sadržaja.
                  </p>
                  <p>
                    Privremeni kolačići su oni koji se automatski bri&scaron;u
                    kada se zatvori web browser &scaron;to znači da ostaju
                    pohranjeni samo tokom posjete web stranici.
                  </p>
                  <p>
                    Kolačići prve strane su kolačići koji dolaze sa na&scaron;e
                    web stranice koju posjećujete, a{" "}
                    <a href="http://www.immuno-natura.ba">
                      www.immuno-natura.ba
                    </a>
                    &nbsp;koristi gore navedene trajne i privremene kolačiće.
                  </p>
                  <p>
                    Kolačići treće strane su kolačići koji dolaze sa drugih web
                    mjesta koje posjetitelj pregleda, a služe praćenju interneta
                    u marketin&scaron;ke svrhe. Web stranica{" "}
                    <a href="http://www.immuno-natura.ba">
                      www.immuno-natura.ba
                    </a>
                    &nbsp;koristi servis za mjerenje posjećenosti web stranice
                    &bdquo;Google Analytics&ldquo; (pravila o kolačićima možete
                    potražiti na stranici &bdquo;Google Analytics&ldquo;), a
                    kojim se omogućava dobivanje statističkih podataka o
                    posjećenosti stranice i načinu upotrebe stranice.
                  </p>
                  <p>
                    Ukoliko se ne slažete sa upotrebom kolačića, kolačiće možete
                    isključiti u postavkama preglednika koji koristite.
                    Vi&scaron;e informacija o načinu kojim se upravlja
                    kolačićima potražite na stranici web browser-a koji
                    koristite ili na stranici&nbsp;
                    <a href="http://www.allaboutcookies.org/">
                      <strong>http://www.allaboutcookies.org</strong>
                    </a>
                    . Obzirom da web stranica{" "}
                    <a href="http://www.immuno-natura.ba">
                      www.immuno-natura.ba
                    </a>
                    &nbsp;koristi kolačiće isključivo kako bi Vam pružila
                    kvalitetnije korisničko iskustvo, imajte na umu da ćete
                    isključivanjem ili sprječavanjem kolačića onemogućiti
                    kvalitetnije funkcioniranje web browser-a.
                  </p>
                  <h4>
                    8. Va&scaron;a prava kao korisnika web stranice{" "}
                    <a href="http://www.immuno-natura.ba">
                      www.immuno-natura.ba
                    </a>
                  </h4>
                  <p>
                    U odnosu na sve Va&scaron;e podatke koje smo pohranili i
                    koje obrađujemo, u svakome trenutku možete zatražiti
                    informaciju o obradi Va&scaron;ih podataka te ostvariti
                    slijedeća prava:
                  </p>
                  <ul>
                    <li>
                      Pravo na ispravak ili dopunu Va&scaron;ih ličnih podataka
                      kako bi Va&scaron;i podaci uvijek bili tačni i aktuelni.
                    </li>
                    <li>Brisanje ličnih podataka</li>
                    <li>
                      Pravo na ograničenje obrade ličnih podataka (npr. ukoliko
                      osporavate tačnost podataka, u razdoblju dok ne provjerimo
                      njihovu tačnost)
                    </li>
                    <li>Pravo na prenosivost podataka (ako je primjenjivo)</li>
                    <li>
                      Pravo uskraćivanja obrade i kori&scaron;tenja ličnih
                      podataka u marketin&scaron;ke svrhe
                    </li>
                  </ul>
                  <p>
                    Svoja prava možete ostvariti u pisanom obliku na adresu
                    sjedi&scaron;ta Bosna i Hercegovina, Grad Sarajevo, ulica M.
                    Tita 11 ili na email-adresu{" "}
                    <a href="mailto:info@immuno-natura.ba">
                      info@immuno-natura.ba
                    </a>{" "}
                    . Va&scaron;i zahtjevi biti će rije&scaron;eni u najkraćem
                    mogućem roku u skladu sa zakonskim propisima koji reguliraju
                    predmetnu materiju , a najkasnije u roku mjesec dana od dana
                    zaprimanja zahtjeva.
                  </p>
                  <p>
                    Za ostvarivanje Va&scaron;ih prava nužno je da možemo
                    nedvojbeno utvrditi Va&scaron; identitet kako ne bi
                    do&scaron;lo do neautorizirane obrade ličnih podataka.
                  </p>
                  <p>
                    Također, ukoliko smatrate da je povrijeđeno neko od
                    Va&scaron;ih, gore navedenih prava, možete podnijeti
                    prigovor nadležnim službama za za&scaron;titu osobnih
                    podataka.
                  </p>
                  <h4>Kontakt</h4>
                  <p>
                    Sve dodatne informacije vezane za za&scaron;titu ličnih
                    podataka možete dobiti upitom na email adresu&nbsp;
                    <a href="mailto:info@immuno-natura.ba">
                      <strong>info@immuno-natura.ba</strong>
                    </a>{" "}
                    .
                  </p>
                  <h4>OSTALO</h4>
                  <p>Upotreba podataka posjetilaca</p>
                  <p>
                    Web stranica{" "}
                    <a href="http://www.immuno-natura.ba">
                      www.immuno-natura.ba
                    </a>{" "}
                    neće prodavati, iznajmljivati, dijeliti ili otkrivati
                    privatne podatke posjetilaca trećim stranama osim u slučaju
                    primljenog sudskog naloga u slučaju istrage eventualnih
                    protivzakonitih aktivnosti.&nbsp; Jedini izuzetak u ovom
                    pravilu je kori&scaron;tenje podataka potrebnih za
                    organizaciju slanja i isporuke eventualnih testnih
                    po&scaron;iljki u cilju ostvarivanja saradnje, kada će dio
                    podataka podjeliti sa ovla&scaron;tenom firmom koja se bavi
                    dostavom po&scaron;iljaka na predviđene adrese.
                  </p>
                  <h4>Postupanje prema maloljetnicima</h4>
                  <p>
                    Maloljetnici mlađi od 18 godina godina ne smiju koristiti
                    kontaktne formulare i formulare za Newsletter. Ukoliko se
                    ovakve aktivnosti identifikuju neće biti realizovane.
                  </p>
                  <h4>Promjene u izjavi o povjerljivosti podataka</h4>
                  <p>
                    Ovu izjavu o povjerljivosti podataka web stranica{" "}
                    <a href="http://www.immuno-natura.ba">
                      www.immuno-natura.ba
                    </a>
                    &nbsp;može izmijeniti u bilo kom trenutku objavljivanjem
                    izmjenjenog teksta izjave o povjerljivosti podataka u dijelu
                    &ldquo;Izjava o povjerljivosti podataka&rdquo;. Izmjena
                    izjave o povjerljivosti podataka stupa na snagu odmah nakon
                    objave na web stranici{" "}
                    <a href="http://www.immuno-natura.ba">
                      www.immuno-natura.ba
                    </a>
                    &nbsp;. Svaki korisnik sam je odgovoran i dužan periodično
                    pregledati ovu stranicu i proučiti bilo kakve promjene u
                    Izjavi o povjerljivosti podataka. Nastavak upotrebe ove web
                    stranice od strane posjetilaca nakon stupanja na snagu
                    takvih izmjena, podrazumijeva da posjetilac potvrđuje i
                    prihvata sve uslove tako izmijenjene Izjave o povjerljivosti
                    podataka.
                  </p>
                  <h4>Linkovi i veze na druge web stranice</h4>
                  <p>
                    Web stranica{" "}
                    <a href="http://www.immuno-natura.ba">
                      www.immuno-natura.ba
                    </a>
                    &nbsp;može sadržati linkove na druge web stranice. Ako
                    posjetilac odluči iskoristiti taj link te pređe na drugu web
                    stranicu, web stranica{" "}
                    <a href="http://www.immuno-natura.ba">
                      www.immuno-natura.ba
                    </a>
                    &nbsp;ne odgovara za za&scaron;titu privatnosti
                    posjetiočevih podataka na toj web stranici.
                  </p>
                  <h4>Nadležnost</h4>
                  <p>
                    Posjetilac koristi web stranicu{" "}
                    <a href="http://www.immuno-natura.ba">
                      www.immuno-natura.ba
                    </a>
                    &nbsp;na vlastitu inicijativu i rizik, i odgovornost za
                    po&scaron;tivanje lokalnih zakonskih odredbi i pravila je
                    isključivo na posjetiocu. Greenwells doo&nbsp;ne garantuje
                    da je upotreba ove web stranice u skladu sa zakonskim
                    normama ili dostupna za upotrebu u bilo kojoj stranoj zemlji
                    ili pod bilo kojom drugom nadležno&scaron;ću osim zemlje u
                    kojoj je njeno sjedi&scaron;te a to je Bosna i Hercegovina.
                  </p>
                  <p>
                    Web stranica{" "}
                    <a href="http://www.immuno-natura.ba">
                      www.immuno-natura.ba
                    </a>{" "}
                    zadržava pravo da ograniči pristup bilo kojoj nadležnosti,
                    području ili državi po svom sopstvenom nahođenju.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span className="_dec3">
        <img src={shape6} alt="Dec 3" />
      </span>
    </section>
  </Layout>
)

export default ZastitaPodataka
